.test-heading{
    padding: 10px;
    /* margin: 0 auto; */
    display: flex;
    color: #fff !important;
    margin-bottom: 0.2rem !important;
    margin-top: 0px;
    
}

@media only screen and (max-device-width: 480px) {
    .heading-container{
        border-bottom: 1px solid #183a1d;
    }
}

.mark-time-container{
    display: flex;
   
}

@media only screen and (max-device-width: 480px) {
    .mark-time-container{
        padding: 5px !important;
    }
}


.instructions>p{
    color: #fff;
    margin: 15px;
    padding: 10px;
    background: #193541;
    /* box-shadow: rgba(11, 24, 30, 1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}

.warning-instruction{
    color:#b80000 !important;
    font-weight: bold;
}

#inst-heading{
    color:#212221;
    font-size: 18px;
    display: flex;
    justify-content: center;
    font-weight: 700;
}

#gen-instructions{
    display: block;
    padding: 2rem;
    max-width: 1050px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    margin: 0 auto;
    margin-top: 2rem;
    background: #47575e;
    color: #fff;
    margin: 10px;
}

.gen-heading{
    color: #fff !important ;
}

#gen-instructions>p{
    margin: 8px;
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
}

#next-btn{
    display: flex;
    justify-content: space-evenly;
    background: #cfe4ed;
}

#cat-heading{
    display: flex;
    justify-content: center;
    margin:20px;
    color:#212221;
    margin-top:2rem;
    background: #cfe4ed;
}

#marks-div{
    display: flex;
    flex-direction: row;
    font-weight: bold;
}

#time-div{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    justify-content: flex-end;
}

.marks-time{
    padding:5px;
    color:#b80000;
}

.test-btn{
    background-color: #00c7b6;
    border-color: #00c7b6;
    color:#0b181e;
    margin-bottom: 2rem;
    margin-top: 1rem;
    
}

.test-btn:hover {
    background-color: #00c7b6;
    border-color: #00c7b6;
    color:#0b181e;
}

.test-btn:focus{
    color: #0b181e;
    background-color: #00c7b6;
    border-color: #00c7b6;
    
}

.head-div{
    text-align: left;
    background-color: #47575e;
    /* padding: 10px; */
    margin-right: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px; */
}

#timer{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
}

.time-left{
    background: #0e1e25;
    padding:1px;
    color: #fff;
    width:30px;
}

.timer-space{
    font-size:1.25rem;
    font-weight: 800;
}

#symbol-status{
    display: grid;
    justify-content: center;
    padding: 10px;
    margin-top: 0.5rem;
}

.symbols>li{
    list-style: none;
    padding-bottom: 10px;
    /* display: inline-block; */
}

.symbol-text{
    margin-top: 4px;
    margin-left: 5px;
}

#test-ins-div{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    padding:1rem;
    background: #cfe4ed;
}

.ins-btn{
    pointer-events: none;
}

.ins-container{
    background: #cfe4ed;
    display: flex;
justify-content: center;
}

.test-head-div{
    display: block !important;
    background: #47575e;
}

.heading-timer{
    display: flex;
}

.ant-typography + h1.ant-typography, .ant-typography + h2.ant-typography, .ant-typography + h3.ant-typography, .ant-typography + h4.ant-typography, .ant-typography + h5.ant-typography {
    margin-top: 0px;
  }

.next-btn{
    margin:2rem;
    padding: 0rem 1rem 0rem 1rem;
}  

.marks-timer-div{
    display: flex;
    justify-content: space-between;
}

.head-title{
    display: block !important;
}






