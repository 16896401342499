.mainContainer{
    width:100%;
    /* height: 450px; */
    background-image: url(../images/topContainer.jpeg);
    background-size: cover;
    background-position: 0px -150px;
}

.bgFilter{
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    background-color: rgba(225, 238, 221, 0.85)
}

.UpperLayer {
    margin-top: 2rem;
    text-align: center;
  }

  .DescText {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin: 20px;
}

.Features{
    flex: 0 0 auto;
    margin-left: 2rem;
    text-align: justify;
    /* width: 100%; */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #25150e;
    margin:10px;
    }

.main-title{
    display: flex;
    color: #0e1e25;
}    

#top-container-btn{
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 2rem;
    font-weight: 700;
  
}

#signup-btn-col{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}
    