.ant-page-header {
    margin: 2rem;
  }

  .subcat-heading-div{
      margin-top:0;
      display: block !important;
      background: #2c4049;
      justify-content: space-evenly;
      color: aliceblue;
  }

  .ant-page-header-heading-title {
    width:100%;
    color: aliceblue;
  }

  .ant-page-header-heading-sub-title {
    white-space: break-spaces;
  }

  h2.ant-typography, .ant-typography h2 {
      color: #fff;
  }

  .ant-page-header-heading-sub-title {
      color: #fff;
  }

  .ant-breadcrumb a {
    color: #fff;
  }

  .ant-breadcrumb-separator {
    color: #fff;
  }

  .ant-breadcrumb > span:last-child {
    color: #fff;
  }

  .sub-card-container{
      background: #e4e7e8;
  }

  .subcat-card-div{
      background: #e4e7e8;
      margin: 2rem;
      padding: 1rem;
  }

  .subcat-desc{
    font-size: 16px;
    font-weight: 400;
  }

  .ant-page-header-heading-left {
    flex-wrap: inherit;
  }

  .ant-page-header-heading-title {
   
    white-space: pre-wrap;
    
  }

  .block-row{
    display: block !important;
    
  }