.ant-card-meta-title{
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    flex-wrap: wrap;
    white-space: break-spaces;
}

.ant-card-actions{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ant-card-body {
    background: #234b5d;
  }

.ant-card-meta-title{
      color: #fff;
  }

.ant-card-actions> li{
    width: 100% !important;
} 

.ant-btn-primary {
    border-color: #408aaa;
    background: #408aaa;
    
  }

  .ant-btn-primary:active {
    color: #fff;
    border-color: #0d1c22;
    background: #0d1c22;
  }

  .ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff;
    border-color: #336e88;
    background: #336e88;
  }

  .ant-btn{
    white-space: break-spaces;
  }

  .ant-card-actions {
    background: #cfe4ed;
  }

  .ant-btn-link {
    color: #25150e;
  }

  .ant-btn-link:active {
    color: #244c5e;
  }

  .ant-btn-link:hover, .ant-btn-link:focus {
    color: #244c5e;
  }

  #card-layout{
      /* background: #2d3f47; */
      background: #616f75;
  }

  .ant-card-bordered {
    border: 1px solid #244c5e;
  }

  .ant-space {
    display: flex;
    justify-content: space-evenly;
  }

  .quiz-action-btn{
    background: #2c4049 !important;
    color: #fff !important;
  }

  .quiz-action-btn:hover{
    background: #47575e !important;
    color: #fff !important;
  }

  .quiz-btn{
    color: #fff !important;
  }

  .subcat-quiz-link{
    color: #2c4049;
  }

  .subcat-quiz-link:hover{
    color: #616f75;
  }