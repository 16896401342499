.btn-group, .btn-group-vertical {
    display: block;
    box-shadow: none;
    
}

.btn-group:hover, .btn-group-vertical:hover {
    display: block;
    box-shadow: none;
    
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 3px;
    padding: 10px;
}

.nav-btn{
    padding: 10px;
    margin: 3px;
    width: 38px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

#submit-div{
    display: flex;
    justify-content: center;
    border-top: 1px solid #000;
    padding: 10px;
    margin-top: 2rem;
}

.nav-btn-checked{
    padding: 10px;
    margin: 3px;
    width: 38px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background-color: #73a24e;
    border-color: #73a24e;
    color:#fff;
}

.nav-btn-current{
    padding: 10px;
    margin: 3px;
    width: 38px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background-color: #b3b3b3;
    border-color: #b3b3b3;
    
}

.nav-btn-visited{
    padding: 10px;
    margin: 3px;
    width: 38px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background-color: #d03f2f;
    border-color: #d03f2f;
    color:#fff; 
    
}

.indicator-btn{
    padding:2px;
    pointer-events: none;
    margin-left: 3px;
    margin-top: 5px;
}


.sub-btn{  
        background-color: #2b6777;
        border-color: #2b6777;
        padding: 0.5rem 1rem;
        color: #fff;
        cursor: pointer;
        margin-right: 10px;
}

.sub-btn:hover,.sub-btn:active, .sub-btn:focus {
    background-color: #2b6777;
    border-color: #2b6777;
    box-shadow: 0 0 0 .25rem #e1eedd;
}

.btn-check:active + .sub-btn:focus, .btn-check:checked + .sub-btn:focus, 
.sub-btn.active:focus, .sub-btn:active:focus, .show > 
.sub-btn.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem #c8d8e4;
}

.test-summary{
    border-bottom: 1px solid #000;
    margin-top:0.2rem;
    margin-bottom: 0.2rem;
    padding-left: 0.2rem;
    font-size: 12px;
    font-weight: 600;
}

.indicator-text{
    margin-top: 0.9rem;
    margin-left: 5px;
}

.test-summary-list{
    list-style: none;
    margin-bottom:0.5rem;
}

@media only screen and (max-device-width: 480px) {
    .test-summary-list{
       display:inline-flex;
    }
}

.modal-title{
    text-align: center;
}

#ques-heading{
    display: flex;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-weight: 700;
    font-size: 18px;
  }

  .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 0px;
  }

  .modal-row{
      background: #e4e7e8;
  }