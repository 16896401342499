#subcat-card > div{
    background: #fff;
}

.ant-card-head-title {
    display: flex;
    flex: 1 1;
    padding: 16px 0;
    overflow: revert-layer;
    white-space: normal;
    justify-content: center;
  }

.ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#subcat-card > .ant-card-head{
    background: #0e1e25;
    color: #fff;
}

#subcat-card > .ant-card-body{
    background: #969fa3;
    color: #fff;
}

.subcat-btn{
    background: #2d3f47;
    border-color: #2d3f47;
    color: #fff;
    margin-left: 25%;
    margin-right: 25%;
}

.subcat-btn:hover, .subcat-btn:focus {
    color: #fff;
    border-color: #0e1e25;
    background: #0e1e25;
  }

  .subcat-btn:active {
    color: #fff;
    border-color: #0e1e25;
    background: #0e1e25;
  }
  .subcat-btn:hover, .subcat-btn:focus {
    color: #fff;
    border-color: #0e1e25;
    background: #0e1e25;
  }

  .subcat-quiz-desc{
    font-weight: 500;
    margin-top: 10px;
    color: #0e1e25;
    font-size: 16px;
  }
