.logo {
  float: left;
  margin: -2.5rem;
}

  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: #0e1e25;
  }

  .ant-layout-header {
    background: #0e1e25;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #408aaa;
  }

  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #408aaa;
  }

  .ant-menu-dark .ant-menu-item:focus-visible, .ant-menu-dark .ant-menu-submenu-title:focus-visible {
    box-shadow: 0 0 0 2px #408aaa;
  }

  .marginer-link{
    margin-left: 20%;
  }

  .marginer-link-double{
    margin-left: 20%;
  }

  .nav-login{
    color: #0e1e25;
    font-weight: 700;
  }

  .login-signup-links:hover{
    background: none !important;
    color: #fff;
  }

  .login-signup-links:focus-visible{
    background: none !important;
    box-shadow: none;
  }

  .login-signup-links:visited{
    background: none !important;
    box-shadow: none;
  }
  
  .ant-btn-link{
    color: #fff !important;
  }

  .ant-btn-link:hover, .ant-btn-link:focus {
    color: #fff;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #0e1e25;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #0e1e25;
  }

  .wctext{
    color: #00c7b6 !important;
    font-size:16px;
  }

  .usr-btn{
    background: #00c7b6 !important;
    color: #083532;
  }

  .nav-login{
    background: #00c7b6 !important;
    color: #083532;
    border-color: #00c7b6;
    /* font-weight: 600; */
  }

  .usr-btn:hover{
    background: #15847b !important;
    color: #fff;
  }

  .ant-btn-default{
    background: #15847b !important;
    color: #fff;
    border:#15847b;
  }

  .nav-login:hover, .ant-btn-default:hover{
    background: #00ad9f !important;
    color: #fff;
    border-color: #00ad9f;
    /* font-weight: 600; */
  }

.ant-dropdown-trigger{
  background: #15847b !important;
  border-color: #15847b;
  }

  .ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
    color: #fff;
  }

 