.about-us-div{
    padding-top:3rem;
    padding-bottom:  3rem;
    background: #f5f7fa ;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f5f7fa, #c3cfe2);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f5f7fa , #c3cfe2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: block;
    height: 100%;
    min-height:530px;
}

.ant-collapse-header{
    background: #47575e;
    color: #fff !important;
}

.ant-collapse-content-box{
    background: #616f75;
    color: #fff;
}

.page-content-col{
    margin:10%
}