@import url('https://fonts.googleapis.com/css?family=Inter');

.performance-card{
    display: flex;
    /* background:#f2f2f2; */
    border-radius: 4px;
    /* box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
    justify-content:space-evenly;
    /* padding: 1.2rem; */
    /* padding-top: 1.2rem; */
    padding-bottom: 1.2rem;
}

.performance-card-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-icons{
    display: flex;
}

.result-block{
    /* padding: 1rem; */
    margin: 2rem 0rem 1rem 0rem;
    display: block;
    /* margin-left: 4rem;
    margin-right: 4rem; */
    
}

.btn-container{
    margin-bottom:3rem;
}

.card-icon{
    border-radius: 50%;
    padding:4px;
    
}

.icon-img{
    width: 40px;
    height: 40px;
    padding:3px;
}

.scorecard-text{
    /* margin: 3px 0px 0px 15px; */
    font-size: 14px;
}

.scorecard-text>p{
    display: flex;
    flex-direction: initial;
    margin-bottom: 0px;
    margin-top: 5px;
    margin-left: 1rem;
    justify-content: space-around;

}

#score-icon{
    background: #0ece6d;
}

#rank-icon{
    background: #e4174f;
}

#attempted-icon{
    background: #8942e5;
}

#accuracy-icon{
    background: #0ad0f4;
}

#percentile-icon{
    background: #6c62f9;
}

.result-table{
    text-align: center;
    vertical-align: middle;
    font-size:14px;
    font-weight: 700;
    margin-left:10px;
    margin-right: 10px;
}

.progress-bar{
    width: 75%;
    height: 3rem;
    
}

.total-ques{
    padding:0 !important;
    margin:0;
    background: #e4e7e8 !important;
    border-left-width: 1px;
    border-right: 0.5px solid #0e1e25;
}

.attempt-ques{
    padding:0 !important;
    margin:0;
    background: #e4e7e8 !important;
    border-left-width: 1px;
    box-shadow: none !important;
    border-right: 0.5px solid #0e1e25;
   
}

.score-ques{
    padding:0 !important;
    margin:0;
    background: #e4e7e8 !important;
    border-right-width: 1px;
    border-left-width: 1px;
    box-shadow: none !important;
    border-right: 0.5px solid #0e1e25;
   
}

.total-progress{
    background: #8942e5;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.attempt-progress{
    background: #39395f;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.score-progress{
    background: #2b6777;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ques-tooltip{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background: #6c757d;
    color: #fff;
    border: #6c757d;
}

.ques-tooltip:hover, .ques-tooltip:focus, .ques-tooltip:focus-visible{
    background: #6c757d;
    color: #fff;
    border: #6c757d;
}

.ques-tooltip-correct{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background: #00b300;
    color: #fff;
    border: #00b300;
}

.ques-tooltip-correct:hover, .ques-tooltip-correct:focus{
    background: #00b300;
    color: #fff;
    border: #00b300;
}

.btn:hover{
    color:#fff;
}

.ques-tooltip-wrong{
    margin-left:10px;
    margin-right:10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    background:#990000;
    color: #fff;
    border: #990000;
}

.ques-tooltip-wrong:hover{
    background:#990000;
    color: #fff;
    border: #990000;
}

.ant-tooltip-inner {
    min-width: 450px;
    max-width: 100%;
    
  }


.question-review{
    display: flex;
    color: #fff !important;
    background: #cacfd1 !important;
    flex-wrap: wrap;
    padding:5px;
}

.legend-holder{
    /* display: inline-grid; */
    display: contents;
    padding: 10px;
    margin-left: 3%;
    font-weight: 400;
    justify-content: center;
}

.legend-holder-div{
    display: flex;
    justify-content: center;
    line-height: 2.5rem;
    align-items: center;
    flex-direction: column;
}

.legend-demo{
    width: 25px;
    height: 30px;
    margin-left: 5%;
    cursor:unset !important;
    margin-top: 0.5rem;
    pointer-events: none;
}


.ques-tooltip-check:focus, .ques-tooltip:focus {
    outline: 0;
    box-shadow: none;
}

.ques-tooltip-correct-check:focus, .ques-tooltip-correct:focus {
    outline: 0;
    box-shadow: none;
}

.ques-tooltip-wrong:focus, .ques-tooltip-wrong:focus {
    outline: 0;
    box-shadow: none;
}

.tooltip-ques{
    color: #fff !important;
}

.summary-report{
    font-weight: 400;
    font-family: sans-serif;
}

.tooltip-div{
    /* margin:10px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.ant-btn:empty {
    visibility: visible;
  }

  .question-analysis-table{
      /* background: #47575e; */
      color: #fff;
  }

/* Style 8
   ----------------------------- */
   .eight h1 {
    text-align:center;
   
    text-transform:uppercase;
    font-size:26px; letter-spacing:1px;
    
    /* display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 21px 0;
    grid-gap: 22px; */
  }
  
  .eight h1:after,.eight h1:before {
    content: " ";
    display: block;
    border-bottom: 2px solid #ccc;
    /* background-color:#f8f8f8; */
  }

  .result-summary{
      justify-content: center;
  }

  .score-card > .ant-card-body{
      background: #cfe4ed;
      /* border: 1px solid #244c5e; */
  }

  .ant-statistic-title {
    color: #0e1e25;
    font-size: 18px;
  }

  .table-dark{
    background: #25150e;
    color: #fff;
  }

  .summary-table-body{
      background: #47575e;
      color: #fff;
      border: 2px dotted #00b300;
  }

.score-card{
    margin:10px;
}

.table-success{
    background: #47575e;
}
  

body{
    font-family: 'Inter';
    font-weight: 700;
    }

html, body {
    /* width: auto!important;  */
    overflow-x: hidden!important
} 
