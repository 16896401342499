#ques-div-sol{
    height: 100%;
    padding-bottom: 60px;
    
}

.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    margin-left: 15px;
    vertical-align: inherit;
}


.checkmark_circle {
    position: absolute;
    width:22px;
    height:22px;
    background-color: green;
    border-radius:11px;
    left:0;
    top:0;
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
}

.sol-container{
    margin-bottom: 1rem;
}

.quiz-solutions{
    margin: 2%;
}

.ques-sol{
        padding: 0;
        font-size: 16px;
        line-height: 1.4;
        word-break: normal;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: start;
        /* color: #222; */
        background-color: initial;
        border: none;
        border-radius: 0;
        font-weight: 600;
        display: ruby-base-container;
        display: flex;
        justify-content: flex-start;
        flex-wrap: unset;
}

.ques-sol-heading{
    background: #47575e;
    color: #fff;
}

.option-heading{
    background: #e4e7e8;
}