*, *:before, *:after {
    box-sizing: border-box;
    }
    
    .footer {
    display: flex;
    flex-flow: row wrap;
    padding: 0.5em;
    color: #fff;
    background-color: #242428;
    }
    
    .footer > * {
    flex:  1 100%;
    }
    
    .l-footer {
    margin-right: 1.25em;
    display: flex;
    padding-left: 1rem;
    justify-content: space-evenly;
    }

    .l-footer > h2 {
        margin-top: 10px;
        
    }
    
    h2 {
    font-weight: 400;
    font-size: 15px;
    }
    
    .footer ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
    }
    
    .footer li {
        display:inline;
        margin-right: 1rem;
    /* line-height: 2em; */
    }
    
    .footer a {
    text-decoration: none;
    }
    
    .r-footer {
    display: flex;
    flex-flow: row wrap;
    }
    
    .r-footer > * {
    flex: 1 50%;
    margin-right: 1.25em;
    }
    
    .box a {
    color: #999;
    }
    
    .h-box {
    column-count: 2;
    column-gap: 1.25em;
    }
    
    .b-footer {
    text-align: center;
    color: #999;

    }
    
    .l-footer p {
    padding-right: 20%;
    color: #999;
    }   
    
    @media screen and (min-width: 600px) {
    .r-footer > * {
    flex: 1;
    }
    
    .features {
    flex-grow: 2;
    }
    
    .l-footer {
    flex: 1 0px;
    }
    
    .r-footer {
    flex: 2 0px;
    }

    .r-footer > li > h2{
        color:#fff;
    }
}

.footer-links{
    color: #fff !important;
}

.footer-links:hover{
    color: #999 !important;
}
    