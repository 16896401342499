#home-content{
    background: #616f75;
}

.ant-layout-sider-children {
    background: #0e1e25;
  }

  .ant-layout-sider {
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
  }

  .sidebar{
    background: #0e1e25;
  }
