.progress-table{
    text-align: center;
}

.progress-table-body{
    font-weight: 650 !important;
    font-family: sans-serif;
    background: #969fa3;
}

.table-dark {
    --bs-table-bg: #C50;
    border-color: #C50;
}

.progress-text{
    text-align: center;
    color:brown;
    font-weight: 600;
}

.progress-heading{
    display: flex;
    justify-content: center;
    margin:10px;
}

.hscroll {
    overflow-x: auto; /* Horizontal */
  }